class AudioManager {
    constructor() {
      if (!window.AudioContext && !window.webkitAudioContext) {
        throw new Error('Web Audio API is not supported in this browser');
      }
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioBuffers = new Map();
      this.activeNodes = new Map();
      this.isLooping = false;
    }
  
    async resumeAudioContext() {
      if (this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }
    }
  
    setLoopAll(looping) {
      this.isLooping = looping;
      this.activeNodes.forEach(nodes => {
        nodes.source.loop = looping;
      });
    }
  
    async loadSound(url) {
      try {
        if (this.audioBuffers.has(url)) {
          return this.audioBuffers.get(url);
        }
  
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch audio file');
        
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
        this.audioBuffers.set(url, audioBuffer);
        return audioBuffer;
      } catch (error) {
        console.error('Error loading sound:', error);
        throw error;
      }
    }
  
    async playSound(url, options = {}) {
      try {
        await this.resumeAudioContext();
        
        const buffer = this.audioBuffers.get(url);
        if (!buffer) {
          throw new Error('Sound not loaded');
        }
  
        const source = this.audioContext.createBufferSource();
        const gainNode = this.audioContext.createGain();
        const panNode = this.audioContext.createStereoPanner();
  
        source.buffer = buffer;
        source.loop = options.loop || this.isLooping;
  
        source.connect(gainNode);
        gainNode.connect(panNode);
        panNode.connect(this.audioContext.destination);
  
        gainNode.gain.value = options.volume || 1;
        panNode.pan.value = options.pan || 0;
  
        source.start(0);
        this.activeNodes.set(url, { source, gainNode, panNode });
        
        // Add event listener for when sound ends
        source.onended = () => {
          if (!source.loop) {
            this.activeNodes.delete(url);
          }
        };
  
        return { source, gainNode, panNode };
      } catch (error) {
        console.error('Error playing sound:', error);
        throw error;
      }
    }
  
    isPlaying(url) {
      return this.activeNodes.has(url);
    }
  
    stopSound(url) {
      const nodes = this.activeNodes.get(url);
      if (nodes) {
        try {
          nodes.source.stop();
        } catch (error) {
          console.warn('Error stopping sound:', error);
        }
        this.activeNodes.delete(url);
      }
    }
  
    stopAll() {
      this.activeNodes.forEach(nodes => {
        try {
          nodes.source.stop();
        } catch (error) {
          console.warn('Error stopping sound:', error);
        }
      });
      this.activeNodes.clear();
    }
  
    setVolume(url, volume) {
      const nodes = this.activeNodes.get(url);
      if (nodes) {
        nodes.gainNode.gain.value = Math.max(0, Math.min(1, volume));
      }
    }
  
    setPan(url, pan) {
      const nodes = this.activeNodes.get(url);
      if (nodes) {
        nodes.panNode.pan.value = Math.max(-1, Math.min(1, pan));
      }
    }
  
    cleanup() {
      this.stopAll();
      this.audioBuffers.clear();
      this.activeNodes.clear();
      if (this.audioContext.state !== 'closed') {
        this.audioContext.close();
      }
    }
  }
  
  // Export a singleton instance
  export const audioManager = new AudioManager();
  
  // Also export the class if needed
  export { AudioManager };