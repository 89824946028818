import React, { useRef, useEffect } from 'react';
import { useDrag } from 'react-dnd';

const DraggableSound = ({ sound, onPlay, isPlaying }) => {
  const audioRef = useRef(new Audio(sound.src));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'sound',
    item: { id: sound.id, name: sound.name, src: sound.src },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('ended', handleAudioEnd);
    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
      audio.pause();
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isPlaying]);

  const handleAudioEnd = () => {
    onPlay(sound, false);
  };

  const handlePlayStop = () => {
    onPlay(sound, !isPlaying);
  };

  return (
    <div ref={drag} className="sound-sample" style={{ opacity: isDragging ? 0.5 : 1 }}>
      {sound.name}
      <p />
      <button onClick={handlePlayStop} className="play-stop-sound-sample">
        {isPlaying ? 'Stop' : 'Play'}
      </button>
    </div>
  );
};

export default DraggableSound;