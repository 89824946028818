const API_URL = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_API_URL_PROD 
  : process.env.REACT_APP_API_URL_DEV;

class ApiService {
  constructor() {
    this.baseUrl = API_URL;
  }

  getHeaders() {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    };
  }

  async fetchSounds() {
    const response = await fetch(`${this.baseUrl}/sounds`, {
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to fetch sounds');
    return response.json();
  }

  async fetchPublicArrangements() {
    const response = await fetch(`${this.baseUrl}/userArrangements/public-arrangements`, {
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to fetch public arrangements');
    return response.json();
  }

  async fetchUserArrangements() {
    const response = await fetch(`${this.baseUrl}/userLibraries`, {
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to fetch user arrangements');
    return response.json();
  }

  async saveArrangement(arrangement) {
    const response = await fetch(`${this.baseUrl}/userLibraries/save`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ arrangement })
    });
    if (!response.ok) throw new Error('Failed to save arrangement');
    return response.json();
  }

  async deleteArrangement(userId, arrangementId) {
    const response = await fetch(`${this.baseUrl}/userLibraries/${userId}/arrangements/${arrangementId}`, {
      method: 'DELETE',
      headers: this.getHeaders()
    });
    if (!response.ok) throw new Error('Failed to delete arrangement');
    return response.json();
  }
}

export const apiService = new ApiService();