// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiService } from '../utils/api.service';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      
      if (token) {
        try {
          // First, check if token is expired
          const payload = JSON.parse(atob(token.split('.')[1]));
          const isExpired = Date.now() >= payload.exp * 1000;
          
          if (isExpired) {
            console.log('Token expired, logging out...');
            logout();
            setIsLoading(false);
            return;
          }

          // Token not expired, verify with backend
          const response = await apiService.get('/verify-token', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          
          if (response.data.valid) {
            setIsLoggedIn(true);
            setUserId(payload.user.id);
          } else {
            console.log('Token invalid, logging out...');
            logout();
          }
        } catch (error) {
          console.error('Token verification failed:', error);
          logout();
        }
      } else {
        setIsLoggedIn(false);
        setUserId(null);
      }
      setIsLoading(false);
    };

    verifyToken();
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    console.log('token valid, setting isLoggedIn to true...');
    setIsLoggedIn(true);
    const payload = JSON.parse(atob(token.split('.')[1]));
    const userId = payload.user.id;
    localStorage.setItem('userId', userId);
    setUserId(userId);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    console.log('token removed from local storage, setting isLoggedIn to false...');
    setIsLoggedIn(false);
    setUserId(null);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or your loading component
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, userId, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};