import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';
import Arranger from '../components/Arranger';
import UserLibraryList from '../components/UserLibraryList';
import LoginLogoutButton from '../components/LoginLogoutButton';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { audioManager } from '../utils/audio/audioManager';
import { apiService } from '../utils/api.service';

function MyLibrary() {
  const [droppedSounds, setDroppedSounds] = useState(Array(5).fill(null));
  const [isLooping, setIsLooping] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userLibraryArrangements, setUserLibraryArrangements] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useAuth();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [audioNodes, setAudioNodes] = useState({});

  // Fetch user arrangements
  const fetchUserLibraryArrangements = useCallback(async () => {
    if (!userId) return;
    
    setIsLoading(true);
    try {
      const data = await apiService.fetchUserArrangements();
      setUserLibraryArrangements(data.arrangements);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error fetching arrangements:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserLibraryArrangements();
  }, [fetchUserLibraryArrangements, location.pathname]);

  // Update loop state
  useEffect(() => {
    audioManager.setLoopAll(isLooping);
  }, [isLooping]);

  const handleDrop = useCallback(async (item, slotIndex) => {
    setIsLoading(true);
    try {
      const newDroppedSounds = [...droppedSounds];
      newDroppedSounds[slotIndex] = item.name;
      setDroppedSounds(newDroppedSounds);
  
      // Preload the sound using audioManager
      await audioManager.loadSound(item.src);
      
      // Now setAudioNodes is defined
      setAudioNodes(prevNodes => ({
        ...prevNodes,
        [slotIndex]: { src: item.src }
      }));

    } catch (error) {
      console.error('Error loading sound:', error);
    } finally {
      setIsLoading(false);
    }
  }, [droppedSounds]);
  
  // Handle arrangement loading
  const handleLoadArrangement = useCallback((arrangement) => {
    try {
      // Stop any currently playing sounds
      audioManager.stopAll();
      
      // Load new arrangement
      arrangement.sounds.forEach(async (sound, index) => {
        if (sound) {
          await audioManager.loadSound(sound.src);
        }
      });

      // Update dropped sounds
      setDroppedSounds(arrangement.sounds.map(sound => sound ? sound.name : null));
    } catch (error) {
      console.error('Error loading arrangement:', error);
    }
  }, []);

  // Handle arrangement deletion
  const handleDeleteArrangement = useCallback(async (arrangement) => {
    try {
      await apiService.deleteArrangement(userId, arrangement._id);
      setUserLibraryArrangements(prev => 
        prev.filter(arr => arr._id !== arrangement._id)
      );
    } catch (error) {
      console.error('Error deleting arrangement:', error);
    }
  }, [userId]);

  // Playback controls
  const playAllSounds = useCallback(() => {
    try {
      droppedSounds.forEach((soundName, index) => {
        if (soundName) {
          audioManager.playSound(soundName, {
            loop: isLooping
          });
        }
      });
      setIsPlaying(true);
    } catch (error) {
      console.error('Error playing sounds:', error);
    }
  }, [droppedSounds, isLooping]);

  const stopAllSounds = useCallback(() => {
    audioManager.stopAll();
    setIsPlaying(false);
  }, []);

  const clearLoadedSounds = useCallback(() => {
    audioManager.stopAll();
    setDroppedSounds(Array(5).fill(null));
    setIsPlaying(false);
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="my-library-page">
        {/* Header section */}
        <header className="my-library-header">
          <div className="header-content">
            <input 
              type="text" 
              placeholder="Search for sounds or creators..." 
              className="search-input"
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Link to="/create" className='icon-link'>
              <div className='create-icon'>
                <h2 className="create-title">CREATE</h2>
              </div>
            </Link>
            <Link to="/my-library" className='icon-link'>
              <div className='my-library-icon'>
                <h2 className="my-library-title" style={{fontSize: 60}}>MY LIBRARY</h2>
              </div>
            </Link>
            <Link to="/discover" className='icon-link'>
              <div className='discover-icon'>
                <h2 className="discover-title">DISCOVER</h2>
              </div>
            </Link>
          </div>
          <div className='right-header-content'>
            <LoginLogoutButton />
            <Link to="/" className="icon-link">
              <div className="home-icon">
                <span className="icon-text">Home</span><br />
              </div>
            </Link>
          </div>
        </header>

        {/* Library content */}
        <div className="user-library-arrangements">
          {isLoading ? (
            <div className="loading">Loading arrangements...</div>
          ) : (
            <UserLibraryList 
              arrangements={userLibraryArrangements}
              onSelect={handleLoadArrangement}
              onDelete={handleDeleteArrangement}
            />
          )}
        </div>

        {/* Player section */}
        <div className="player-my-library-page">
          {droppedSounds.map((droppedSound, index) => (
            <Arranger 
              key={index} 
              onDrop={handleDrop} 
              index={index} 
              droppedSound={droppedSound}
            />
          ))}

          {/* Controls */}
          <div className="loop-toggle">
            <label>
              <input
                type="checkbox"
                checked={isLooping}
                onChange={(e) => setIsLooping(e.target.checked)}
              />
              <b> LOOP</b>
            </label>
          </div>

          <div className="button-group">
            <button 
              onClick={isPlaying ? stopAllSounds : playAllSounds} 
              className="play-all-button"
            >
              {isPlaying ? 'Stop' : 'Play'}
            </button>
            <button onClick={clearLoadedSounds} className="clear-button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default MyLibrary;