import { useState } from 'react';
import { apiService } from '../utils/api.service';

export const useArrangementSave = (userId, isPrivate) => {
  const [saveMessage, setSaveMessage] = useState('');

  const saveToLibrary = async (sounds, droppedSounds) => {
    try {
      // Validate arrangement
      if (!droppedSounds.length || droppedSounds.every(sound => sound == null)) {
        setSaveMessage('Cannot save an empty arrangement. Please add some sounds.');
        return;
      }

      // Format sound objects
      const soundObjects = droppedSounds
        .filter(soundName => soundName)
        .map(soundName => {
          const fullSoundObject = sounds.find(sound => sound.name === soundName);
          return fullSoundObject ? {
            name: fullSoundObject.name,
            src: fullSoundObject.src,
          } : null;
        })
        .filter(Boolean);

      const arrangement = {
        userId,
        sounds: soundObjects,
        isPrivate
      };

      await apiService.saveArrangement(arrangement);
      setSaveMessage('Arrangement saved successfully!');

    } catch (error) {
      console.error('Error saving arrangement:', error);
      setSaveMessage(error.message || 'Failed to save arrangement');
      throw error;
    }
  };

  return { saveMessage, saveToLibrary };
};