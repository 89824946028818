import React, { createContext, useState, useEffect } from 'react';
import { apiService } from '../utils/api.service';

export const SoundsContext = createContext();

export const SoundsProvider = ({ children }) => {
  const [sounds, setSounds] = useState([]);

  useEffect(() => {
    const soundsList = [
      { id: 1, name: 'Light Rain', key: 'light-rain-30-min.wav' },
      { id: 2, name: 'Soft Sketch', key: 'Soft Sketch.wav' },
      { id: 3, name: 'Light Rain, Drone Bass (C)', key: 'Light-Rain_Drone-Bass_C.wav' },
      { id: 4, name: 'Soft Drone Bass (C)', key: 'Soft-Drone-Bass_C.wav' },
      { id: 5, name: 'Soft Flute (C)', key: 'Soft-Flute_C.wav' },
    ];

    const loadSoundUrls = async () => {
      try {
        const soundsWithUrls = await Promise.all(
          soundsList.map(async (sound) => {
            try {
              const response = await apiService.get(`/get-file/${sound.key}`);
              return {
                ...sound,
                src: response.data.url
              };
            } catch (error) {
              console.error(`Error loading URL for ${sound.name}:`, error);
              return sound;
            }
          })
        );
        setSounds(soundsWithUrls);
      } catch (error) {
        console.error('Error loading sound URLs:', error);
      }
    };

    loadSoundUrls();
  }, []);

  return (
    <SoundsContext.Provider value={{ sounds, setSounds }}>
      {children}
    </SoundsContext.Provider>
  );
};